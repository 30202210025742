import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { handleSuccess, handleError } from "../CustomAlerts";
import { Link } from "react-router-dom";
import moment from "moment";
const initialFieldValues = {
  appointmentId: "00000000-0000-0000-0000-000000000000",
  appointmentNo: "",
  appointmentDateTime: new Date(),
  bookingStatus: "BOOKED",
  branchEmployeeId: "00000000-0000-0000-0000-000000000000",
  discount: "",
  duration: "",
  id: "00000000-0000-0000-0000-000000000000",
  modeOfPayment: "CASH",
  paymentStatus: "PENDING",
  paymentPlace: "VENUE",
  rating: 0,
  review: 0,
  startDateTime: new Date(),
  endDateTime: new Date(),
  subTotal: 0,
  tax: 0,
  total: 0,
};
export default function AppointmentsView(props) {
  const [values, setValues] = useState(initialFieldValues);
  const [errors, setErrors] = useState({});
  const [employeeName, setEmployeeName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeePhone, setEmployeePhone] = useState("");
  const [serviceslist, setServicesList] = useState([]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validate = () => {
    let temp = {};
    temp.bookingStatus = values.bookingStatus === "0" ? false : true;
    temp.paymentStatus = values.paymentStatus === "0" ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === true);
  };
  const applicationAPI = (
    url = "https://munnywebapi.azurewebsites.net/api/appointment/"
  ) => {
    return {
      fetchAll: () => axios.get(url + "get"),
      fetchAppointmentsView: () =>
        axios.get(
          url + "getbyid?AppointmentId=" + props.match.params["appointmentId"]
        ),
      update: (id, updateRecord) =>
        axios.put(url + "update/" + id, updateRecord),
      fetchBusinessEmployee: (id) =>
        axios.get(
          "https://munnywebapi.azurewebsites.net/api/branchemployee/getbyid?Id=" +
            id
        ),
        fetchAppointmentService: () =>
        axios.get(
          "https://munnywebapi.azurewebsites.net/api/AppointmentService/GetByAppointmentId?AppointmentId=" +
          props.match.params["appointmentId"]
        ),
    };
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("appointmentId", values.appointmentId);
      formData.append("appointmentNo", values.appointmentNo);
      formData.append("appointmentDateTime", values.appointmentDateTime);
      formData.append("bookingStatus", values.bookingStatus);
      formData.append("branchEmployeeId", values.branchEmployeeId);
      formData.append("discount", values.discount);
      formData.append("duration", values.duration);
      formData.append("id", values.id);
      formData.append("modeOfPayment", values.modeOfPayment);
      formData.append("paymentStatus", values.paymentStatus);
      formData.append("paymentPlace", values.paymentPlace);
      formData.append("rating", values.rating);
      formData.append("review", values.review);
      formData.append("startDateTime", values.startDateTime);
      formData.append("endDateTime", values.endDateTime);
      formData.append("subTotal", values.subTotal);
      formData.append("tax", values.tax);
      formData.append("total", values.total);
      addOrEdit(formData);
    }
  };
  const addOrEdit = (formData, onSuccess) => {
    applicationAPI()
      .update(formData.get("appointmentId"), formData)
      .then((res) => {
        handleSuccess("Appointment Details Updated");
      });
  };
  function refreshAppointmentsView() {
    applicationAPI()
      .fetchAppointmentsView()
      .then((res) =>
        refreshBusinessEmployee(res.data.data.branchEmployeeId, res.data.data)
      )
      .catch((err) => console.log(err));
  }
  function refreshBusinessEmployee(id, appData) {
    console.log(appData);
    applicationAPI()
      .fetchBusinessEmployee(id)
      .then((res) => (setEmployeeName(res.data.data.name),setEmployeeEmail(res.data.data.email),setEmployeePhone(res.data.data.mobile)), setValues(appData))
      .catch((err) => console.log(err));
  }
  function getAppointmentService() {
    applicationAPI()
      .fetchAppointmentService()
      .then((res) => setServicesList(res.data.data))
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    refreshAppointmentsView();
    getAppointmentService();
  }, []);
  const applyErrorClass = (field) =>
    field in errors && errors[field] === false ? " form-control-danger" : "";
  return (
    <div className="container-fluid">
      <Header />
      <div className="row main-content">
        <div className="col-sm-3 col-xs-6 sidebar pl-0">
          <Sidebar />
        </div>
        <div className="col-sm-9 col-xs-12 content pt-3 pl-0">
          <div className="row border-bottom mb-4">
            <div className="col-sm-8 pt-2">
              <h6 className="mb-4 bc-header">Update Appointments</h6>
            </div>
            <div className="col-sm-4 text-right pb-3">
              <Link
                to={"/appointmentsList"}
                className="btn btn-round btn-theme"
              >
                <i className="fa fa-list" />
                Appointments List
              </Link>
            </div>
          </div>
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <span className="text-secondary">
              Dashboard
              <i className="fa fa-angle-right" />
              Appointments List
            </span>
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="mt-4 mb-3 p-3 button-container bg-white border shadow-sm">
                  <h6 className="mb-3">Appointments Details</h6>
                  <div className="form-group row floating-label">
                    <div className="col-sm-4 col-12">
                      <label htmlFor="appointmentNo">AppointmentNo : {values.appointmentNo}</label>
                    </div>
                    <div className="col-sm-4 col-12">
                      <label htmlFor="appointmentDate">AppointmentDateTime : {moment(values.appointmentDateTime).format("MMMM Do YYYY hh:mm a")}</label>
                    </div>
                    <div class="col-lg-4 col-12">
                      <label htmlFor="bookingStatus">BookingStatus : {values.bookingStatus}</label>
                    </div>
                  </div>
                  <div className="form-group row floating-label">
                  <div className="col-sm-4 col-12">
                      <label htmlFor="paymentStatus">PaymentStatus : {values.paymentStatus}</label>
                    </div>
                    <div className="col-sm-4 col-12">
                      <label htmlFor="modeOfPayment">ModeOfPayment : {values.modeOfPayment}</label>
                    </div>
                    <div className="col-sm-4 col-12">
                      <label htmlFor="subTotal">SubTotal : {values.subTotal}</label>
                    </div>
                  </div>
                  <div className="form-group row floating-label">
                    <div className="col-sm-4 col-12">
                      <label htmlFor="discount">Discount :{values.discount}</label>
                    </div>
                    <div className="col-sm-4 col-12">
                      <label htmlFor="total">Total : {values.total}</label>
                    </div>
                    <div className="col-sm-4 col-12">
                      <label htmlFor="tax">Tax : {values.tax}</label>
                    </div>
                  </div>
                  <div className="form-group row floating-label">
                    <div className="col-sm-4 col-12">
                      <label htmlFor="review">Review : {values.review}</label>
                    </div>
                    <div class="col-lg-4 col-12">
                      <label htmlFor="rating">Rating : {values.rating}</label>
                    </div>
                    <div className="col-sm-4 col-12">
                      <label htmlFor="startTime">StartTime : {moment(values.startDateTime).format("MMMM Do YYYY hh:mm a")}</label>
                    </div>
                  </div>
                  <div className="form-group row floating-label">
                  <div className="col-sm-4 col-12">
                      <label htmlFor="endTime">EndTime : {moment(values.endDateTime).format("MMMM Do YYYY hh:mm a")}</label>
                    </div>
                    <div className="col-sm-4 col-12">
                      <label htmlFor="duration">Duration : {values.duration}</label>
                    </div>
                  </div>
                  <div className="form-group row floating-label">
                    <div className="col-sm-4 col-12">
                      <label htmlFor="employeeName">Employee Name : {employeeName}</label>
                    </div>
                    <div className="col-sm-4 col-12">
                      <label htmlFor="employeeName">Employee Email : {employeeEmail}</label>
                    </div>
                    <div className="col-sm-4 col-12">
                      <label htmlFor="employeeName">Employee Mobile : {employeePhone}</label>
                    </div>
                  </div>
                  <div className="form-group row floating-label">
                    <div className="col-sm-4 col-12"></div>
                    <div className="table-responsive product-list">
                      <table
                        className="table table-bordered table-striped mt-3"
                        id="appointmentsList"
                      >
                        <thead>
                          <tr>
                            <th>Service Name</th>
                            <th>Duration</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {serviceslist &&
                            serviceslist.map((app) => (
                              <tr key={app.serviceId}>
                                <td>{app.serviceName}</td>
                                <td>{app.durationHours}Hrs : {app.durationMinutes}Mins</td>
                                <td>{app.price}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
