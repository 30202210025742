import React from 'react';
import { Link } from 'react-router-dom';
export default function Footer()
{
    return (
        <div className="row mt-2 mb-2 footer">
            <div className="col-sm-8">
                <span>All rights reserved ©  2020 by Munny Finds</span>
            </div>            
        </div>
    )
}