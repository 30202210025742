import React, { useState, useEffect } from "react";
import axios from "axios";
import { handleSuccess, handleError } from "../../CustomAlerts";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import Footer from "../../Footer";
const initialFieldValues = {
  testimonialId: 0,
  name: "",
  review: "",
  rating: "",
  isHomePage: false
};
export default function Testimonials(props) {
  const [categoryList, setCategoryList] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [values, setValues] = useState(initialFieldValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (recordForEdit !== null) setValues(recordForEdit);
  }, [recordForEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validate = () => {
    let temp = {};
    temp.title = values.title === "" ? false : true;
    temp.subTitle = values.subTitle === "0" ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("testimonialId", values.testimonialId);
      formData.append("name", values.name);
      formData.append("review", values.review);
      formData.append("rating", values.rating);
      formData.append("isHomePage", values.isHomePage);
      addOrEdit(formData);
    }
  };
  const applicationAPI = (url = "https://munnywebapi.azurewebsites.net/api/testimonial/") => {
    return {
      fetchAll: () => axios.get(url + "get"),
      fetchHome: () => axios.get(url + "gethomepage"),
      create: (newRecord) => axios.post(url + "insert", newRecord),
      update: (id, updateRecord) =>
        axios.put(url + "update/" + id, updateRecord),
        delete: (id) => axios.delete(url + "delete/" + id)
    };
  };
  const addOrEdit = (formData, onSuccess) => {
    if (formData.get("testimonialId") === "0") {
      applicationAPI()
        .create(formData)
        .then((res) => {
          console.log(res);
          handleSuccess("New Data Added");
          resetForm();
          refreshTestimonialList();
        });
    } else {
      applicationAPI()
        .update(formData.get("testimonialId"), formData)
        .then((res) => {
          handleSuccess("Data Details Updated");
          resetForm();
          refreshTestimonialList();
        });
    }
  };
  const resetForm = () => {
    setValues(initialFieldValues);
  };
  function refreshTestimonialList() {
    applicationAPI()
      .fetchAll()
      .then((res) => setCategoryList(res.data))
      .catch((err) => console.log(err));
  }
  const showEditDetails = (data) => {
    setRecordForEdit(data);
  };
  const onDelete = (e, id) => {
    if (window.confirm("Are you sure to delete this record?"))
      applicationAPI()
        .delete(id)
        .then((res) => {
          console.log(res);
          handleSuccess("Data Deleted Succesfully");
          refreshTestimonialList();
        })
        .catch((err) => handleError("Data Deleted Failed"));
  };
  useEffect(() => {
    //refreshAboutUsList();
    refreshTestimonialList();
  }, []);

  const applyErrorClass = (field) =>
    field in errors && errors[field] === false ? " form-control-danger" : "";
  return (
    <div className="container-fluid">
      <Header />
      <div className="row main-content">
        <div className="col-sm-3 col-xs-6 sidebar pl-0">
          <Sidebar />
        </div>
        <div className="col-sm-9 col-xs-12 content pt-3 pl-0">
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <span className="text-secondary">
              Dashboard <i className="fa fa-angle-right" /> Testimonials
            </span>
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="mt-4 mb-3 p-3 button-container bg-white border shadow-sm">
                  <h6 className="mb-3">Testimonials</h6>
                  <div className="form-group row floating-label">
                    <div className="col-sm-8 col-12">
                      <input
                        className={"form-control" + applyErrorClass("name")}
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="title">Name</label>
                    </div>
                    <div className="col-sm-4 col-12">
                      <select name="rating" value={values.rating} onChange={handleInputChange} className="form-control">
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                      <label htmlFor="paraGraph1">Rating</label>
                    </div>
                  </div>
                  <div className="form-group row floating-label">
                    <div className="col-sm-12 col-12">
                      <input
                        className={
                          "form-control" + applyErrorClass("review")
                        }
                        name="review"
                        type="text"
                        value={values.review}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="paraGraph">Review</label>
                    </div>
                  </div>
                  <div className="form-group row floating-label">
                    <div className="col-sm-4 col-12">
                      <button type="submit" className="btn btn-primary mr-3">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive product-list">
            <table
              className="table table-bordered table-striped mt-3"
              id="categoryList"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Review</th>
                  <th>Rating</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {categoryList.map((category) => (
                  <tr key={category.testimonialId}>
                    <td>{category.name}</td>
                    <td>{category.review}</td>
                    <td>{category.rating}</td>
                    <td>
                      <button
                        className="btn btn-success btn-sm mr-2"
                        onClick={() => {
                          showEditDetails(category);
                        }}
                      >
                        <i className="fas fa-pencil-alt" />
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={(e) =>
                          onDelete(e, parseInt(category.testimonialId))
                        }
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
