import React, { useState, useEffect } from "react";
import axios from "axios";
import { handleSuccess, handleError } from "../../CustomAlerts";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import Footer from "../../Footer";
const initialFieldValues = {
  serviceId: "00000000-0000-0000-0000-000000000000",
  categoryId: "00000000-0000-0000-0000-000000000000",
  branchId: "00000000-0000-0000-0000-000000000000",
  serviceName: "",
  description: "",
  price: "",
  durationHours: 0,
  durationMinutes: 0,
  status: "true"
};
export default function ServiceList() {
  const [values, setValues] = useState(initialFieldValues);
  const [serviceList, setServiceList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (recordForEdit !== null) setValues(recordForEdit);
  }, [recordForEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validate = () => {
    let temp = {};
    temp.serviceName = values.serviceName === "" ? false : true;
    temp.status = values.status === "0" ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("serviceId", values.serviceId);
      formData.append("serviceName", values.serviceName);
      formData.append("description", values.description);
      formData.append("branchId", values.branchId);
      formData.append("categoryId", values.categoryId);
      formData.append("durationHours", values.durationHours);
      formData.append("durationMinutes", values.durationMinutes);
      formData.append("price", values.price);
      formData.append("status", values.status);
      addOrEdit(formData, resetForm);
    }
  };
  const applicationAPI = (url = "https://munnywebapi.azurewebsites.net/api/service/") => {    
    return {
      fetchBranches: () =>
        axios.get("https://munnywebapi.azurewebsites.net/api/branch/GetActiveBranches"),
      fetchCategory: () =>
        axios.get("https://munnywebapi.azurewebsites.net/api/category/GetByStatus"),
      fetchAll: () => axios.get(url + "get"),
      create: (newRecord) => axios.post(url + "insert", newRecord),
      update: (updateRecord) =>
        axios.put(url + "update", updateRecord),
      delete: (id) => axios.delete(url + "delete?id=" + id),
    };
  };
  const addOrEdit = (formData) => {
    if (formData.get("serviceId") === "00000000-0000-0000-0000-000000000000") {
      applicationAPI()
        .create(formData)
        .then((res) => {
          handleSuccess("New Service Added");
          resetForm();
          refreshServiceList();
        });
    } else {
      applicationAPI()
        .update(formData)
        .then((res) => {
          handleSuccess("Service Details Updated");
          resetForm();
          refreshServiceList();
        });
    }
  };
  const showEditDetails = (data) => {
    setRecordForEdit(data);
  };
  const onDelete = (e, id) => {
    if (window.confirm("Are you sure to delete this record?"))
      applicationAPI()
        .delete(id)
        .then((res) => {
          handleSuccess("Service Deleted Succesfully");
          refreshServiceList();
        })
        .catch((err) => handleError("Service Deleted Failed"));
  };
  const resetForm = () => {
    setValues(initialFieldValues);
  };
  function refreshServiceList() {
    applicationAPI()
      .fetchAll()
      .then((res) => setServiceList(res.data.data))
      .catch((err) => console.log(err));
  }
  function refreshCategories() {
    applicationAPI()
      .fetchCategory()
      .then((res) => setCategoryList(res.data.data))
      .catch((err) => console.log(err));
  }
  function refreshBranches() {
    applicationAPI()
      .fetchBranches()
      .then((res) => setBranchList(res.data.data))
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    refreshServiceList();
    refreshBranches();
    refreshCategories();
  }, []);
  const applyErrorClass = (field) =>
    field in errors && errors[field] === false ? " form-control-danger" : "";
  return (
    <div className="container-fluid">
      <Header />
      <div className="row main-content">
        <div className="col-sm-3 col-xs-6 sidebar pl-0">
          <Sidebar />
        </div>
        <div className="col-sm-9 col-xs-12 content pt-3 pl-0">
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <span className="text-secondary">
              Dashboard <i className="fa fa-angle-right" /> Service
            </span>
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="mt-4 mb-3 p-3 button-container bg-white border shadow-sm">
                  <h6 className="mb-3">Service Details</h6>
                  <div className="form-group row floating-label">
                    <div className="col-sm-3 col-12">
                      <select
                        name="branchId"
                        type="text"
                        value={values.branchId}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        <option value="0">Please Select</option>
                        {branchList.map((bus) => (
                          <option value={bus.branchId}>
                            {bus.businessName}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="businessName">Business</label>
                    </div>
                    <div className="col-sm-3 col-12">
                      <select
                        name="categoryId"
                        type="text"
                        value={values.categoryId}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        <option value="0">Please Select</option>
                        {categoryList.map((bus) => (
                          <option value={bus.categoryId}>
                            {bus.categoryName}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="categoryName">Category</label>
                    </div>
                    <div className="col-sm-3 col-12">
                      <input
                        className={
                          "form-control" + applyErrorClass("serviceName")
                        }
                        name="serviceName"
                        type="text"
                        value={values.serviceName}
                        onChange={handleInputChange}
                        placeholder="Service Name"
                      />
                      <label htmlFor="serviceName">Service Name</label>
                    </div>
                    <div className="col-sm-3 col-12">
                      <input
                        className={
                          "form-control" + applyErrorClass("price")
                        }
                        name="price"
                        type="Number"
                        value={values.price}
                        onChange={handleInputChange}
                        placeholder="Price"
                      />
                      <label htmlFor="price">Price</label>
                    </div>
                  </div>
                  <div className="form-group row floating-label">
                    <div className="col-sm-3 col-12">
                      <input
                        className={
                          "form-control" + applyErrorClass("durationHours")
                        }
                        name="durationHours"
                        type="Number"
                        value={values.durationHours}
                        onChange={handleInputChange}
                        placeholder="durationHours"
                      />
                      <label htmlFor="durationHours">Duration Hours</label>
                    </div>
                    <div className="col-sm-3 col-12">
                      <input
                        className={
                          "form-control" + applyErrorClass("durationMinutes")
                        }
                        name="durationMinutes"
                        type="Number"
                        value={values.durationMinutes}
                        onChange={handleInputChange}
                        placeholder="durationMinutes"
                      />
                      <label htmlFor="durationMinutes">Duration Minutes</label>
                    </div>
                    <div className="col-sm-3 col-12">
                      <input
                        className={
                          "form-control" + applyErrorClass("description")
                        }
                        name="description"
                        type="text"
                        value={values.description}
                        onChange={handleInputChange}
                        placeholder="description"
                      />
                      <label htmlFor="description">Description</label>
                    </div>
                    <div className="col-sm-3 col-12">
                      <select
                        value={values.status}
                        onChange={handleInputChange}
                        className="form-control"
                        name="status"
                      >
                        <option value="true">active</option>
                        <option value="false">inactive</option>
                      </select>
                      <label htmlFor="status">Status</label>
                    </div>
                  </div>
                  <div className="form-group row floating-label">
                    <div className="col-sm-4">
                      <button type="submit" className="btn btn-primary mr-3">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={resetForm}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive product-list">
                    <table
                      className="table table-bordered table-striped mt-3"
                      id="serviceList"
                    >
                      <thead>
                        <tr>
                          <th>Business</th>
                          <th>Category</th>
                          <th>Service</th>
                          <th>Price</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceList.map((ser) => (
                          <tr key={ser.serviceId}>
                            <td>{ser.businessName}</td>
                            <td>{ser.categoryName}</td>
                            <td>{ser.serviceName}</td>
                            <td>{ser.price}</td>
                            <td>{ser.status ? "active" : "inactive"}</td>
                            <td>
                              <button
                                className="btn btn-success btn-sm mr-2"
                                onClick={() => {
                                  showEditDetails(ser);
                                }}
                              >
                                <i className="fas fa-pencil-alt" />
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={(e) => onDelete(e, ser.serviceId)}
                              >
                                <i className="fas fa-trash-alt" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
